import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import './add-user.scss';
import { Button, notification, Select } from 'antd';
import { Auth } from 'aws-amplify';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import Utils from '../../../../utils/utils';

const CPAddUser = forwardRef((props, ref) => {
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    password: ""
  });
  const [reqErr, setReqErr] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [role, setRole] = useState('content_partner');
  // const [factbriefRole, setFactbriefRole] = useState('factbrief_writer');
  const [claimsDBRole, setClaimsDBRole] = useState('claimsdb_user');
  const [drafteraiRole, setDrafterAIRole] = useState('drafterai_user');
  const [selectedOrg, setSelectedOrg] = useState(props.org_id || null);
  const [selectedOrgData, setSelectedOrgData] = useState(null);


  console.log(props)

  const history = useHistory();
  const { Option } = Select;
  const orgList = props.orgList;

  useEffect(() => {
    setIsAdmin(localStorage.getItem('user_types').includes('org_admin'));
  }, []);

  useEffect(() =>{
    let filteredOrg;
    if (props.org_id && props.org_id !== 0) {
      setSelectedOrg(props.org_id);
      filteredOrg = orgList?.filter(org => org.page_id == props.org_id)
    }
    else {
      filteredOrg = orgList?.filter(org => org.page_id == selectedOrg)
    }
    if (filteredOrg?.length === 1) {
      setSelectedOrgData(filteredOrg[0]);
    }

  }, [props.org_id, selectedOrg]);

  const handleOnChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useImperativeHandle(ref, () => ({
    registerUser
  }));

  const updateUser = async (userId, firstName, lastName, roles, claimsdbRole,drafteraiRole) => {
    try {

      const api = Utils.getAPIDescription('update-user');
      const body = {
        userid: userId,
        firstname: firstName,
        lastname: lastName,
        // role: roles,
        factbrief_service: { role: roles },
        claimsdb_service: { role: claimsdbRole },
        drafterai_service:{ role: drafteraiRole }
      };
      const response = await axios.post(api, body);
      if (response.data && response.data.statusCode === 200) {
        notification.success({ message: "Information updated successfully." });
        if (userId === localStorage.getItem('user_id')) {
          localStorage.setItem('firstname', firstName);
          localStorage.setItem('lastname', lastName);
        }
      } else {
        throw new Error('Update failed');
      }
    } catch (error) {
      console.error('Error updating user:', error);
      notification.error({ message: "Failed to update user information" });
    }
  };


  const sendSetupEmail = (email, password) => {
    const usersList = [
      {
        user_email: email,
        username: email,
        password: password
      }];

    const apiEndpoint = Utils.getAPIDescription("send-setup-email-to-users");

    axios.post(apiEndpoint, {"users_list": usersList})
        .then(response => {
          console.log('Emails sent successfully:', response.data);
        })
        .catch(error => {
          console.error('Error sending emails:', error);
        });

  };


  const registerUser = () => {
    const attributes = {
      'custom:first_name': formData.fname,
      'custom:last_name': formData.lname,
      'custom:role': role,
	    'custom:org_id': props.modal ? selectedOrg.toString() : localStorage.getItem('org_id').toString(),
    };

    Auth.signUp({
      username: formData.email,
      password: formData.password,
      attributes: attributes
    })
    .then(res => {
      if (res?.userConfirmed) {
        sendSetupEmail(formData.email, formData.password);
      }
      if (props.type === 'services_modal') {
        props.setVisibility({ visible: false, type: null, userId: null });
        if (res?.userConfirmed) {
			console.log(res?.userSub)
          updateUser(res?.userSub, formData.fname, formData.lname, role, claimsDBRole,drafteraiRole);
          notification.success({ message: "User has been added successfully", duration: 5, placement: 'topRight' });
        }
      } else {
        if (!props.modal) {
          history.push('/partnerconsole/edit-profile#2');
        } else {
          props.setVisibility(false);
        }
        if (res?.userConfirmed) {
          notification.success({ message: "User has been added successfully", duration: 5, placement: 'topRight' });
        }
      }
    })
    .catch(err => {
      console.log(err);
      notification.error({ message: err.message, duration: 5, placement: 'topRight' });
    });
  };

  const checkForm = () => {
    const requiredFields = [formData.fname, formData.lname, formData.email, formData.password, role, selectedOrg];
    if (props.modal && requiredFields.includes("")) {
      setReqErr(true);
    } else if (!props.modal && requiredFields.slice(0, -1).includes("")) {
      setReqErr(true);
    } else {
      registerUser();
    }
  };

  return (
    <div className="app-workspace">
      <div className="app-content">
        {isAdmin ? (
          <div className={props.modal ? "" : "add-cpuser-container pull-left"}>
            {!props.modal && (
              <button className='blue-border-btn mb-4 fn-14' onClick={() => history.goBack()}>BACK</button>
            )}
            <div className='create-heading'>Add Team Member</div>
            <div className={props.modal ? "" : "add-user"}>
              <div className="name-section">
                <div className="name-div">
                  <div className="fn-16 fnw-600 mb-2">First Name:</div>
                  <input className="user-input" name="fname" onChange={handleOnChange} value={formData.fname} />
                  {!formData.fname && reqErr && <div className="err-msg">This field is required</div>}
                </div>
                <div className="name-div ml-5">
                  <div className="fn-16 fnw-600 mb-2">Last Name:</div>
                  <input className="user-input" name="lname" onChange={handleOnChange} value={formData.lname} />
                  {!formData.lname && reqErr && <div className="err-msg">This field is required</div>}
                </div>
              </div>
              <div className="fn-16 fnw-600 mb-2 mt-5">E-mail:</div>
              <input className="user-input" name="email" onChange={handleOnChange} value={formData.email} />
              {!formData.email && reqErr && <div className="err-msg">This field is required</div>}
              <div className="fn-16 fnw-600 mb-2 mt-5">
                Password: 
                <span className='ml-2 blue-font font-weight-bold fn-14 pull-right cursor-pointer' onClick={() => setShowPassword(!showPassword)}>
                  {!showPassword ? 'Show' : 'Hide'}
                </span>
              </div>
              <input type={!showPassword ? "password" : "text"} className="user-input" name="password" onChange={handleOnChange} value={formData.password} />
              {!formData.password && reqErr && <div className="err-msg">This field is required</div>}
              {orgList?.length > 0 && props.modal && (
                <div className="fn-16 fnw-600 mt-5">
                  <span>Organization:</span>
                  <span className='select-wrapper ml-2'>
                    <Select
                      style={{ width: '200px' }}
                      bordered={false}
                      notFoundContent={null}
                      onChange={(val) => { setSelectedOrg(val); props.setSelectedOrgId(val); }}
                      placeholder="Select an organization"
                      showSearch
                      disabled={props.type === "services_modal"}
                      value={selectedOrg === 0 ? null : selectedOrg}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {orgList?.filter(org => org.org_name !== 'All').map(org =>
                        <Option key={org.page_id} value={org.page_id}>{org.org_name}</Option>
                      )}
                    </Select>
                  </span>
                </div>
              )}
              {!selectedOrg && orgList?.length > 0 && reqErr && <div className="err-msg">This field is required</div>}
              {(
                  props.type === 'services_modal' ? props?.services?.factbrief_service : selectedOrgData?.services?.includes("factbrief_service")) && (
                    <div className="fn-16 fnw-600 mt-5">
                      <span>Factbrief service access level:</span>
                        <span className='select-wrapper ml-2'>
                          <Select style={{ width: '200px' }} bordered={false} notFoundContent={null} onChange={(val) => setRole(val)} defaultValue='content_partner'>
                            <Option value='content_partner_admin'>Admin/Editor</Option>
                            <Option value="content_partner">Writer</Option>
                          </Select>
                        </span>
                    </div>
                )
              }
              {
                (props.type === 'services_modal' ? props?.services?.claimsdb_service : selectedOrgData?.services?.includes("claimsdb_service")) && (
                    <div className="fn-16 fnw-600 mt-5">
                      <span>Parser service access level:</span>
                      <span className="select-wrapper ml-2">
                      <Select
                          style={{ width: '200px' }}
                          bordered={false}
                          notFoundContent={null}
                          onChange={(val) => setClaimsDBRole(val)}
                          placeholder="Select Parser role"
                          defaultValue="claimsdb_user"
                      >
                        <Option value="claimsdb_user">User</Option>
                        <Option value="claimsdb_contributor">Database Contributor</Option>
                      </Select>
                    </span>
                    </div>
                )
              }
              {!role && reqErr && <div className="err-msg">This field is required</div>}
              {(
                  props.type === 'services_modal' ? props?.services?.drafterai_service : selectedOrgData?.services?.includes("drafterai_service")) && (
                    <div className="fn-16 fnw-600 mt-5">
                      <span>Drafter AI Service access level:</span>
                        <span className='select-wrapper ml-2'>
                          <Select style={{ width: '200px' }} bordered={false} notFoundContent={null} onChange={(val) => setDrafterAIRole(val)} defaultValue='drafterai_user'>
                            <Option value='drafterai_user'>User</Option>
                            {/* <Option value="content_partner">Writer</Option> */}
                          </Select>
                        </span>
                    </div>
                )
              }
              {/*{props?.services?.factbrief_service && props.type === 'services_modal' && (*/}
              {/*  <div className="fn-16 fnw-600 mt-5">*/}
              {/*    <span>Factbrief Role:</span>*/}
              {/*    <span className='select-wrapper ml-2'>*/}
              {/*      <Select */}
              {/*        style={{ width: '200px' }} */}
              {/*        bordered={false} */}
              {/*        notFoundContent={null} */}
              {/*        onChange={(val) => setFactbriefRole(val)}*/}
              {/*        placeholder="Select Factbrief role"*/}
              {/*        defaultValue="factbrief_writer"*/}
              {/*      >*/}
              {/*        <Option value="factbrief_writer">Writer</Option>*/}
              {/*        <Option value="factbrief_editor">Editor</Option>*/}
              {/*      </Select>*/}
              {/*    </span>*/}
              {/*  </div>*/}
              {/*)}*/}
              {/*{props?.services?.claimsdb_service && props.type === 'services_modal' && (*/}
              {/*  <div className="fn-16 fnw-600 mt-5">*/}
              {/*    <span>Parser service access level:</span>*/}
              {/*    <span className='select-wrapper ml-2'>*/}
              {/*      <Select */}
              {/*        style={{ width: '200px' }} */}
              {/*        bordered={false} */}
              {/*        notFoundContent={null} */}
              {/*        onChange={(val) => setClaimsDBRole(val)}*/}
              {/*        placeholder="Select Parser role"*/}
              {/*        defaultValue="claimsdb_user"*/}
              {/*      >*/}
              {/*        <Option value="claimsdb_user">User</Option>*/}
              {/*        <Option value="claimsdb_contributor">Database Contributor</Option>*/}
              {/*      </Select>*/}
              {/*    </span>*/}
              {/*  </div>*/}
              {/*)}*/}
              {!props.modal &&
                <div className="mt-5">
                  <Button type="primary" size="large" onClick={checkForm}>{props.org_id && props.addUserKey > 0 ? 'Add User' : 'Create'}</Button>
                </div>
              }

            </div>
          </div>
        ) : (
          <div className="add-user-container pull-left">
            <h2>Permission Denied</h2>
          </div>
        )}
      </div>
    </div>
  );
});


export default CPAddUser;


