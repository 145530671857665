import React from 'react';
import './navbar.scss';
import GFLogo from '../../../../assets/images/logos/gigafact_logo_full.svg';
import {  Badge, Button, Menu, Dropdown, Tooltip } from 'antd';
import { UserOutlined, BellOutlined } from '@ant-design/icons';
// import Utils from '../../../../utils/utils';
import { CognitoLogout } from '../../authentication/authentication';

const Navbar = (props) => {
  const userMenuClick = ({ key }) => {
    if (key === "Logout") {
      // const contentPartner = Utils.checkContentPartnerWriter() || Utils.checkContentPartnerContributor();

      CognitoLogout().then(() => {
        window.location.href = "/partnerconsole/login";
        localStorage.clear();
            // ? Utils.getMinioragneRedirectURI('logout') + "/partnerconsole/login"
            // : Utils.getMinioragneRedirectURI('logout');
      });
    }
  };

  const UserMenu = (
      <Menu onClick={userMenuClick} className="user-menu">
        <Menu.Item key="Logout" className='user-menu-items'>
          <span key="logout_span">Logout </span>
        </Menu.Item>
      </Menu>
  );

  return (
      <div className="navbar-container">
        <div className="logo-block">
          <a href="/partnerconsole/home">
            <img className="fs-logo" src={GFLogo} alt="GigaFact Logo" />
          </a>
        </div>
        {props.isLogin && (
            <div className="right-panel">
              <div className="notification-block">
                <div className='notification-btn' onClick={() => { props.setopenNotificaiton(true) }}>
                  <Badge count={props.notifications?.unread_count || null}>
                    <BellOutlined className='notification-icon' />
                  </Badge>
                </div>
              </div>
              <div className="profile-block">
                <Tooltip placement="leftTop" title={`${localStorage.getItem('firstname')} ${localStorage.getItem('lastname')}`}>
                  <Dropdown overlay={UserMenu} trigger={["click"]} placement="bottomRight">
                    <Button shape="circle" icon={<UserOutlined />} />
                  </Dropdown>
                </Tooltip>
              </div>
            </div>
        )}
      </div>
  );
};

export default Navbar;
