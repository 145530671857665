import React, {useCallback, useEffect, useState} from 'react';
import Footer from "./footer/footer";
import Navbar from './navbar/navbar';
import Notification from "./notifications/notifications";
import SideBar from './sidebar/sidebar';
import axios from 'axios';
import Utils from '../../../utils/utils.js';
import { useLocation, useHistory } from "react-router-dom";
import { CognitoLogout } from '../authentication/authentication';
import SessionTimerService from "../utils/sessionTimerService";

export const Layout = (props) => {
  // const [isLogin, setIsLogin] = useState(false);
  const [openNotificaiton, setopenNotificaiton] = useState(false);
  const [openNotificaitonSettings, setopenNotificaitonSettings] = useState(false);
  const [orgDetails, setOrgDetails] = useState(null);
  const [notifications, setNotifications] = useState(null);

  const [isSessionTimeout, setIsSessionTimeout] = useState(false);


  let location = useLocation();
  const history = useHistory();
  const inactiveTimeOut = 1000 * 60 * 60 * 4;
  let timeOutObject = null;

  // Resetting and setting the timer for inactive state
  useEffect(() => {
    if (props.isLogin) {
      const timer = new SessionTimerService({
        timeout: inactiveTimeOut, //expire after 30 minutes
        onTimeout: () => {
          setIsSessionTimeout(true);
        },
        onExpired: () => {
          //do something if expired on load
          setIsSessionTimeout(true);
        }
      });
      return () => {
        timer.cleanUp();
      };
    }

  }, [props.isLogin]);

  useEffect(() => {

    if (isSessionTimeout) {
      let contentPartner = Utils.checkContentPartnerWriter();
      localStorage.clear();
      CognitoLogout();
      window.location.href = contentPartner ? Utils.getMinioragneRedirectURI('logout') + "/partnerconsole/login" : Utils.getMinioragneRedirectURI('logout');
    }

  }, [isSessionTimeout]);


  useEffect(() => {
    // checkIsLogin();
    // sessionTimeOut();
    // window.addEventListener('storage', checkIsLogin);

    // document.title = "FactSparrow Partner Console";
    document.title = "Gigafact Partner Console";
    // var link = document.querySelector("link[rel~='icon']");
    // if (!link) {
    //   link = document.createElement('link');
    //   link.rel = 'icon';
    //   document.getElementsByTagName('head')[0].appendChild(link);
    // }
    // // link.href = "/fs_favicon.ico";
    // link.href = "/favicon.ico";
    // return () => {
    //   window.removeEventListener('click', sessionTimeOut);
    // }
  }, []);

  useEffect(() => {
    if (location.pathname !== "/partnerconsole/flags") {
      sessionStorage.removeItem("tab_of")
    }
    if(location.hash==="#notification_settings"){
      setopenNotificaitonSettings(true);
      history.push(location.pathname)
    }

  }, [history, location.pathname, location.hash]);

  return (
      <>

        <Navbar isLogin={props.isLogin} openNotificaiton={openNotificaiton} setopenNotificaiton={setopenNotificaiton}
                notifications={notifications} setNotifications={setNotifications}/>
        <SideBar body={props.children} isLogin={props.isLogin} setopenNotificaiton={setopenNotificaiton}
                 openNotificaiton={openNotificaiton} orgDetails={orgDetails}/>
        <Notification body={props.children} isLogin={props.isLogin} openNotificaiton={openNotificaiton}
                      setopenNotificaiton={setopenNotificaiton} notifications={notifications}
                      setNotifications={setNotifications} setopenNotificaitonSettings={setopenNotificaitonSettings}
                      openNotificaitonSettings={openNotificaitonSettings}/>
        <Footer/>
      </>
  )
};
